<template>
  <div class="relative flex min-w-0 items-start text-left">
    <span>
      <img
        v-if="src"
        class="object-cover h-12 w-12 rounded-full"
        :src="src"
        alt=""
      />
      <div
        v-else
        class="h-12 w-12 rounded-full bg-black/30 flex items-center justify-center"
      >
        <SvgIcon name="user" type="outline" class="h-8 w-8 text-white/80" />
      </div>
    </span>
    <div class="ml-3 truncate">
      <div class="flex items-center">
        <div v-if="fullName" class="truncate font-bold text-panel-accent-text">
          {{ fullName }}
        </div>
        <div v-if="isQuoPro" class="min-w-5 mx-0.5">
          <SvgIcon
            name="check-badge"
            type="solid"
            class="w-5 h-5 fill-violet-600"
          />
        </div>
      </div>
      <div class="truncate text-panel-primary-text">@{{ username }}</div>
      <div
        v-if="isQuoPro && showQuoProBadge"
        class="flex text-panel-primary-text mt-1.5 text-sm"
      >
        <div
          class="flex justify-center items-center text-xs bg-gradient-to-r from-violet-700/80 via-blue-500/80 to-sky-500/80 text-white font-semibold rounded whitespace-nowrap px-2 mr-1"
        >
          PRO
        </div>
        #{{ memberNumber }} &middot; {{ $t('profileUser.since') }}
        {{ memberSince }}
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  profile: {
    type: Object,
    default() {
      return {
        avatarUrl: null,
        fullName: null,
        username: null,
        memberNumber: null,
        memberSince: null,
      }
    },
  },
  isQuoPro: {
    type: Boolean,
    default: false,
  },
  showQuoProBadge: {
    type: Boolean,
    default: false,
  },
})
const { locale } = useI18n()
const client = useSupabaseClient()
const src = ref('')

const { memberSinceDate } = useDateTime()

const avatarUrl = computed(() => {
  if (props.profile && props.profile.avatarUrl) {
    return props.profile.avatarUrl
  } else {
    return null
  }
})
const fullName = computed(() => {
  if (props.profile && props.profile.fullName) {
    return props.profile.fullName
  } else {
    return null
  }
})
const username = computed(() => {
  if (props.profile && props.profile.username) {
    return props.profile.username
  } else {
    return null
  }
})

const isQuoPro = computed(() => {
  return props.isQuoPro
})

const memberNumber = computed(() => {
  if (props.profile && props.profile.memberNumber) {
    return props.profile.memberNumber
  } else {
    return null
  }
})

const memberSince = computed(() => {
  if (props.profile && props.profile.memberSince) {
    return memberSinceDate(props.profile.memberSince, locale.value)
  }
  return null
})

const downloadImage = async () => {
  try {
    if (!avatarUrl.value) return
    const { data, error } = await client.storage
      .from('avatars')
      .download(avatarUrl.value, {
        transform: {
          width: 100,
          height: 100,
          quality: 100,
          resize: 'cover',
        },
      })
    if (error) throw error
    src.value = URL.createObjectURL(data)
  } catch (error) {
    // console.error('Error downloading image: ', error.message)
  }
}

await downloadImage()

watch(avatarUrl, () => {
  if (!avatarUrl.value) {
    src.value = ''
    return
  }
  downloadImage()
})
</script>
